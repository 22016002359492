@media screen and (max-width: 40em) {
  .responsiveTable tr:not(:first-child):not(:last-child) {
    border: 0px !important;
    padding: 0 !important;
    margin: 1em 0 !important;
  }
  .responsiveTable tr {
    border: 0px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .responsiveTable td:first-child {
    background: linear-gradient(63deg, #E92EFB 0%, #440BD4 100%) !important;
    color: white !important;
    font-weight: bold;
  }

  .responsiveTable i {
    color: black !important;
  }
}
.MuiSlider-root {
  color: #ddd !important;
  height: 15px !important;
}

.MuiSlider-track {
  background: white !important;
}

.MuiSlider-thumb {
  background: #4b0cd0 !important;
  height: 30px !important;
  width: 30px !important;
}